import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import ServicesHero from "../components/servicesHero/servicesHero";
import ServicesAccordion from "../components/servicesAccordion/servicesAccordion";
import ServicesScenes from "../components/servicesScenes/servicesScenes";
import ServicesSolution from "../components/servicesSolution/servicesSolution";

const ServicesPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Our Services" />
      <ServicesHero data={data.wpPage.servicesHero.servicesHero} />
      <ServicesAccordion data={data.wpPage.servicesAccordion.accordion} />
      <ServicesScenes data={data.wpPage.servicesScenes.servicesScenes} />
      <ServicesSolution data={data.wpPage.servicesSolution.solutions} />
    </Layout>
  );
};

export default ServicesPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "services-en" }) {
      servicesHero {
        servicesHero {
          title
          content
          steps {
            step1 {
              title
              content
            }
            step2 {
              title
              content
            }
            step3 {
              title
              content
            }
            step4 {
              title
              content
            }
          }
        }
      }
      servicesAccordion {
        accordion {
          title
          content
          accordions {
            item1 {
              title
              content
              link {
                label
                url
              }
            }
            item2 {
              title
              content
              link {
                label
                url
              }
            }
            item3 {
              title
              content
              link {
                label
                url
              }
            }
            item4 {
              title
              content
              link {
                label
                url
              }
            }
            item5 {
              title
              content
              link {
                label
                url
              }
            }
            item6 {
              title
              content
              link {
                label
                url
              }
            }
            item7 {
              title
              content
              link {
                label
                url
              }
            }
          }
        }
      }
      servicesScenes {
        servicesScenes {
          title
          scenes {
            scene1 {
              title
              content
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            scene2 {
              title
              content
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            scene3 {
              title
              content
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
      servicesSolution {
        solutions {
          title
          content
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          cards {
            card1 {
              title
              content
            }
            card2 {
              title
              content
            }
            card3 {
              title
              content
            }
          }
        }
      }
    }
  }
`;
